<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from "axios";
import Multiselect from "vue-multiselect";
import NumberInputSpinner from "vue-number-input-spinner";
import { convertDateTime, convertPrice } from "@/_helpers/index";
import Skeleton from "@/components/skeleton.vue";
import imgDummy from "@/assets/images/blank-kaosnyaman.jpeg";

/**
 * Starter page
 */
export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
    NumberInputSpinner,
    Skeleton,
  },
  page: {
    title: "Pemesanan",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Starter Page",
      items: [
        {
          text: "Pemesanan",
          href: "/",
        },
        {
          text: this.$route.name,
          active: true,
        },
      ],
      imgDummy: imgDummy,
      isLoading: false,
      isLoadingModal: false,
      isLoadingModalDetail: false,
      apiUrl: {
        baseUrl: process.env.VUE_APP_BASE_URL,
        customerCategory:
          process.env.VUE_APP_API_BASE_URL + "customer-categories",
        customer: process.env.VUE_APP_API_BASE_URL + "customers",
        order: process.env.VUE_APP_API_BASE_URL + "orders",
        clothColor: process.env.VUE_APP_API_BASE_URL + "cloth-colors",
        cloth: process.env.VUE_APP_API_BASE_URL + "clothes",
      },
      setCustomerCategory: [],
      checkedCustomerCategory: null,
      searchCustomerCategory: {},
      setOptions: [],
      setDataCloth: [],
      dataClothDetail: null,
      setDataClothDetail: [],
      selectedColor: null,
      setDataOrderDetail: [],
      filterClothId: null,
      setDataClothColor: [],
      checkParams: false,
      setDataOrder: {
        payment_method: null, // cash, down payment
        amount_down_payment: 0, //ini diisi ketika metode pembayarannya adalah DP
        discount: 0,
        customer_category: null, // Ini diisi ketika admin menginputkan kategori tiktok / shopee
        customer_id: null, // Ini diisi ketika admin menginputkan kategori selain tiktok / shopee,
        order_details: [
          // {
          //   cloth_size_id: null,
          //   cloth_size_price_id: null,
          //   qty: null,
          // },
        ],
      },
      methodPayments: [
        { text: "Cash", value: "cash" },
        {
          text: "Pembayaran DP",
          value: "down payment",
        },
      ],
      selectedPayment: "cash",
      rowCount: 6,
      rowCountModal: 5,
      rowCountModalDetail: 5,
    };
  },
  watch: {
    async checkedCustomerCategory(val) {
      return this.checkCustomerCategoryInput(val);
    },
    filteredData(newData) {
      this.setDataClothColor = newData[0];
    },
    setDataClothColor(value) {
      if (value) {
        this.selectedColor = value.cloth_colors[0];
      }
    },
  },
  computed: {
    total_qty() {
      let total = 0;
      this.setDataClothDetail.forEach((entry) => {
        entry.cloth_colors.forEach((color) => {
          color.cloth_sizes.forEach((item) => {
            total += parseInt(item.qty);
          });
        });
      });
      return total;
    },
    total_price() {
      let total = 0;
      this.setDataClothDetail.forEach((entry) => {
        entry.cloth_colors.forEach((color) => {
          color.cloth_sizes.forEach((item) => {
            total += parseInt(item.qty) * item.price.price;
          });
        });
      });
      return total;
    },
    total_price_dp() {
      let total = 0;
      total +=
        parseInt(this.total_price) -
        parseInt(this.setDataOrder.amount_down_payment);
      return total;
    },
    total_price_final() {
      let total = 0;
      this.setDataClothDetail.forEach((entry) => {
        entry.cloth_colors.forEach((color) => {
          color.cloth_sizes.forEach((item) => {
            total += parseInt(item.qty) * item.price.price;
          });
        });
      });
      return (total -= parseInt(this.setDataOrder.discount));
    },
    filteredData() {
      return this.setDataClothDetail.filter(
        (item) => item.id === this.filterClothId
      );
    },
    filteredDataFinal() {
      const filteredData = this.setDataClothDetail.filter((item) => {
        return item.cloth_colors.some((color) => {
          return color.cloth_sizes.some((size) => size.qty > 0);
        });
      });

      const filteredDataWithPositiveQty = filteredData.filter((item) => {
        return item.cloth_colors.some((color) => {
          return color.cloth_sizes.some((size) => size.qty > 0);
        });
      });

      console.log(filteredDataWithPositiveQty)

      return filteredDataWithPositiveQty;
    },
  },
  mounted() {
    this.getCustomerCategory();
  },
  methods: {
    convertJSON(value) {
      const convertedObject = JSON.parse(JSON.stringify(value));
      return convertedObject;
    },
    formattedDate(value) {
      return convertDateTime(value);
    },
    formattedPrice(value) {
      return convertPrice(value);
    },
    async handleCheckedCustomerCategory(val) {
      if (this.$route.query.use_cloth_detail) {
        await this.getCloth(this.$route.query.customer_category_id);
        this.checkParams = true;
      } else {
        console.log(val)
        await this.getCloth(val.id);
      }
    },
    handleCustomerCategory(value) {
      if (value.length > 0) {
        const findCustomerCategory = value.find(
          (customerCategory) => customerCategory.name === "Shopee"
        );
        return (this.checkedCustomerCategory = findCustomerCategory);
      }
    },
    async getCustomerCategory() {
      ``;
      try {
        this.isLoading = true;
        await axios.get(this.apiUrl.customerCategory + '?order=sort').then((response) => {
          this.setCustomerCategory = response.data.data;
          this.handleCustomerCategory(response.data.data);
          // customerCategoryId = response.data.data.filter('')
          this.handleCheckedCustomerCategory(this.handleCustomerCategory(response.data.data));
          this.isLoading = false;
        });
      } catch (error) {
        this.isLoading = false;
        if (process.env.NODE_ENV !== "production") {
          console.log(error);
        }
        this.$store.dispatch("notification/error", error);
      }
    },
    async getCloth(customerCategoryId = null, notSetDataClothDetail = true) {
      try {
        this.isLoadingModal = true;
        await axios
          .get(
            this.apiUrl.cloth +
              "?order=sort&ascending=1&use_detail_cloth=1" +
              (customerCategoryId !== null
                ? "&customer_category_id=" + customerCategoryId
                : "")
          )
          .then((response) => {
            this.setDataCloth = response.data.data;
            if (notSetDataClothDetail) {
              this.setDataClothDetail = this.addQtyInClothSize(
                response.data.data
              );
            }
            this.isLoadingModal = false;
          });
      } catch (error) {
        this.isLoadingModal = false;
        if (process.env.NODE_ENV !== "production") {
          console.log(error);
        }
        this.$store.dispatch("notification/error", error);
      }
    },
    async getClothDetail(id) {
      try {
        this.isLoadingModalDetail = true;
        await axios.get(`${this.apiUrl.cloth}/${id}`).then((response) => {
          this.dataClothDetail = response.data;
          this.isLoadingModalDetail = false;
        });
      } catch (error) {
        if (process.env.NODE_ENV !== "production") {
          console.log(error);
        }
        this.$store.dispatch("notification/error", error);
      }
    },
    async getCustomerBySearch(url) {
      try {
        await axios.get(url).then((response) => {
          this.setOptions = response.data.data;
        });
      } catch (error) {
        console.error(error);
      }
    },
    constructUrlFilter(query) {
      if (
        this.searchCustomerCategory.id !== undefined ||
        this.searchCustomerCategory.id !== null
      ) {
        return `${this.apiUrl.customer}?order=sort&customer_category_id=${this.searchCustomerCategory.id}&name=${query}`;
      }
    },
    applyFilter(query) {
      if (query.length > 2) {
        this.getCustomerBySearch(this.constructUrlFilter(query));
      }
    },
    checkCustomerCategoryInput(value) {
      if (value) {
        let transformedValue = {
          text: value.name,
          id: value.id,
          value: null,
        };
        this.searchCustomerCategory = transformedValue;
        // const validNames = ["tiktok", "shopee", "tokopedia"];
        // const lowerCaseName = value.name.toLowerCase();

        // if (validNames.includes(lowerCaseName)) {
        // }
      }
    },
    addFormData() {
      this.$refs["tambah-item-modal"].show();
    },
    addQtyInClothSize(newVal) {
      return newVal.map((item) => {
        const newItem = { ...item };
        newItem.cloth_colors = item.cloth_colors.map((color) => {
          const newColor = { ...color };
          newColor.cloth_sizes = color.cloth_sizes.map((size) => {
            return {
              ...size,
              qty: 0,
            };
          });
          return newColor;
        });
        return newItem;
      });
    },
    addFormDataSize(id) {
      this.$refs["tambah-item-modal-detail"].show();
      this.filterClothId = id;
    },
    totalQtyIndex(id) {
      let total = 0;
      this.setDataClothDetail.forEach((data) => {
        const colorEntry = data.cloth_colors.find((item) => item.id === id);
        if (colorEntry) {
          colorEntry.cloth_sizes.forEach((item) => {
            total += parseInt(item.qty);
          });
        }
      });
      return total;
    },
    totalQtyClothIndex(id) {
      let total = 0;
      const specificData = this.setDataClothDetail.find(
        (item) => item.id === id
      );
      if (specificData) {
        specificData.cloth_colors.forEach((color) => {
          color.cloth_sizes.forEach((size) => {
            total += parseInt(size.qty);
          });
        });
      }
      return total;
    },
    totalRowspan(id) {
      let total = 0;
      this.setDataClothDetail.forEach((data) => {
        const colorEntry = data.cloth_colors.find((item) => item.id === id);
        if (colorEntry) {
          colorEntry.cloth_sizes.forEach((item, index) => {
            if (parseInt(item.qty) > 0) total++;
          });
        }
      });
      return total;
    },
    indexWithStockGreaterThanZeroForColor(colorId) {
      const colorEntry = this.setDataClothDetail.find((data) =>
        data.cloth_colors.find((color) => color.id === colorId)
      );
      if (colorEntry) {
        const clothColorEntry = colorEntry.cloth_colors.find(
          (color) => color.id === colorId
        );
        if (clothColorEntry) {
          return clothColorEntry.cloth_sizes.findIndex(
            (clothSize) => parseInt(clothSize.qty) > 0
          );
        }
      }
      return -1;
    },
    totalPriceIndex(id) {
      let total = 0;
      this.setDataClothDetail.forEach((data) => {
        const colorEntry = data.cloth_colors.find((item) => item.id === id);
        if (colorEntry) {
          colorEntry.cloth_sizes.forEach((item) => {
            total += parseInt(item.qty) * item.price.price;
          });
        }
      });
      return total;
    },
    async onSubmit() {
      let temp = this.setDataClothDetail
      let order_details = this.setDataClothDetail
        .flatMap((item) => {
          return item.cloth_colors.flatMap((color) => {
            return color.cloth_sizes
              .filter((sizeItem) => parseInt(sizeItem.qty) !== 0)
              .map((sizeItem) => {
                return {
                  cloth_size_id: sizeItem.id,
                  cloth_size_price_id: sizeItem.price.id,
                  qty: parseInt(sizeItem.qty),
                };
              });
          });
        })
        .filter(Boolean);
      let saveFormOrder = {
        payment_method:
          this.selectedPayment !== "Pilih Pembayaran"
            ? this.selectedPayment
            : null, // cash, down payment
        amount_down_payment:
          this.setDataOrder.amount_down_payment > 0
            ? parseInt(this.setDataOrder.amount_down_payment)
            : null, //ini diisi ketika metode pembayarannya adalah DP
        customer_category:
          this.searchCustomerCategory.value === null
            ? this.searchCustomerCategory.text
            : null, // Ini diisi ketika admin menginputkan kategori tiktok / shopee
        customer_id:
          this.searchCustomerCategory.value !== null
            ? this.searchCustomerCategory.value.id
            : null, // Ini diisi ketika admin menginputkan kategori selain tiktok / shopee,
        discount:
          parseInt(this.setDataOrder.discount) > 0
            ? parseInt(this.setDataOrder.discount)
            : null,
        order_details: order_details,
      };
      if (this.checkParams) {
        saveFormOrder.use_cloth_detail = true
        saveFormOrder.order_id = this.$route.query.order_id
      }
      if (saveFormOrder.order_details.length > 0) {
        try {
          this.isLoading = true;
          await axios
            .post(`${this.apiUrl.order}`, saveFormOrder, {
              headers: {
                "Content-Type": "application/json",
              },
            })
            .then((resp) => {
              this.isLoading = false;
              if (this.$route.query.use_cloth_detail) {
                this.$router.push(`/penjualan/${this.$route.query.order_id}`);
              } else {
                this.$router.push(`/penjualan/${resp.data}`);
              }
              this.$store.dispatch(
                "notification/success",
                "Data berhasil disimpan"
              );
            });
        } catch (error) {
          this.isLoading = false;
          this.getCloth(this.searchCustomerCategory.id, false)
          // this.setDataClothDetail = temp
          if (process.env.NODE_ENV !== "production") {
            console.log(error);
          }
          this.$store.dispatch("notification/error", error);
        }
      }
    },
  },
  middleware: "authentication",
};
</script>
<template>
  <Layout>
    <PageHeader :title="$route.name" :items="items" />
    <b-card>
      <b-card-body v-if="!isLoading">
        <h5 class="text-bold mb-3">Cari Pelanggan</h5>
        <template v-if="!checkParams">
          <b-row>
            <b-col
              sm="3"
              lg="2"
              class="xs-custom"
              v-for="(customerCategory, index) in setCustomerCategory"
              :key="customerCategory.id"
            >
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  :id="`radio-${customerCategory.id}-${index}`"
                  v-model="checkedCustomerCategory"
                  :name="`radio-${customerCategory.id}-${index}`"
                  :value="customerCategory"
                  @change="handleCheckedCustomerCategory(customerCategory)"
                />
                <label
                  class="form-check-label text-capitalize"
                  :for="`radio-${customerCategory.id}-${index}`"
                  >{{ customerCategory.name }}</label
                >
              </div>
            </b-col>
          </b-row>
          <template v-if="Object.keys(searchCustomerCategory).length !== 0">
            <div
              v-if="
                checkedCustomerCategory.name.toLowerCase() !== 'shopee' &&
                  checkedCustomerCategory.name.toLowerCase() !== 'tiktok' &&
                  checkedCustomerCategory.name.toLowerCase() !== 'tokopedia'
              "
            >
              <multiselect
                v-model="searchCustomerCategory.value"
                :options="setOptions"
                :internal-search="true"
                :searchable="true"
                track-by="id"
                label="name"
                :placeholder="`Cari ${searchCustomerCategory.text}`"
                @search-change="applyFilter"
                class="mt-3"
              >
                <template slot="singleLabel" slot-scope="{ option }">{{
                  option.name
                }}</template>
              </multiselect>
            </div>
          </template>
        </template>
        <template v-else>
          <div class="m-3">
            <p class="fw-bold">
              Kategori Pelanggan : {{ checkedCustomerCategory.name }}
            </p>
          </div>
        </template>
        <div class="mt-4">
          <b-row>
            <b-col class="text-end"
              ><b-button
                variant="primary"
                id="tambah-item-modal"
                @click="addFormData"
                >Tambah Item</b-button
              >
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col>
              <div class="row">
                <div class="col-xl-8">
                  <template v-if="filteredDataFinal.length > 0">
                    <div class="table-responsive">
                      <table class="table table-bordered">
                        <thead class="head-custom fw-bold">
                          <tr class="text-center font-size-15">
                            <td>Gambar</td>
                            <td>Produk</td>
                            <td>Warna</td>
                            <td>Ukuran</td>
                            <td>Pcs</td>
                            <td class="text-right">Harga</td>
                          </tr>
                        </thead>
                        <tbody class="text-center align-middle">
                          <template v-for="item in filteredDataFinal">
                            <template
                              v-for="color in item.cloth_colors"
                              v-if="totalQtyIndex(color.id) > 0"
                            >
                              <tr class="text-center align-middle">
                                <td :rowspan="totalRowspan(color.id)">
                                  <img
                                    :src="
                                      color.image !== null
                                        ? `${apiUrl.baseUrl}storage/${color.image}`
                                        : imgDummy
                                    "
                                    alt
                                    class="avatar-md rounded-1 img-responsive"
                                  />
                                </td>
                                <td
                                  :rowspan="totalRowspan(color.id)"
                                  class="fw-bold"
                                >
                                  {{
                                    item.cloth_category
                                      ? item.cloth_category.name
                                      : "-"
                                  }}
                                </td>
                                <td
                                  :rowspan="totalRowspan(color.id)"
                                  class="fw-bold"
                                >
                                  <div
                                    class="d-flex justify-content-center align-items-center gap-2"
                                  >
                                    <span
                                      class="indicator-color-two border border-1 border-secondary"
                                      :style="{
                                        background: `#${color.color.code_hexa}`,
                                      }"
                                    ></span>
                                  </div>
                                </td>
                                <template
                                  v-if="
                                    color.cloth_sizes.some(
                                      (size) => parseInt(size.qty) > 0
                                    )
                                  "
                                >
                                  <td>
                                    {{
                                      color.cloth_sizes[
                                        indexWithStockGreaterThanZeroForColor(
                                          color.id
                                        )
                                      ].size.name
                                    }}
                                  </td>
                                  <td>
                                    {{
                                      color.cloth_sizes[
                                        indexWithStockGreaterThanZeroForColor(
                                          color.id
                                        )
                                      ].qty
                                    }}
                                  </td>
                                  <td class="text-right">
                                    {{
                                      formattedPrice(
                                        color.cloth_sizes[
                                          indexWithStockGreaterThanZeroForColor(
                                            color.id
                                          )
                                        ].price.price
                                      )
                                    }}
                                  </td>
                                </template>
                              </tr>
                              <tr
                                v-for="(size, indexSize) in color.cloth_sizes"
                                :key="size.id"
                                v-if="
                                  parseInt(size.qty) > 0 &&
                                    indexSize >
                                      indexWithStockGreaterThanZeroForColor(
                                        color.id
                                      )
                                "
                              >
                                <td>{{ size.size.name }}</td>
                                <td>{{ size.qty }}</td>
                                <td class="text-right">
                                  {{ formattedPrice(size.price.price) }}
                                </td>
                              </tr>
                            </template>
                          </template>
                        </tbody>
                        <tfoot class="head-custom">
                          <tr class="fw-bold">
                            <td class="text-end" colspan="4">
                              Total
                            </td>
                            <td class="text-center">{{ total_qty }}</td>
                            <td class="text-right">
                              {{ formattedPrice(total_price) }}
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </template>
                  <template v-else>
                    <div class="text-center text-muted">
                      <span class="uil uil-shopping-cart-alt fs-custom"></span>
                    </div>
                  </template>
                </div>

                <div class="col-xl-4">
                  <div class="mt-5 mt-lg-0">
                    <div class="card border shadow-none">
                      <div
                        class="card-header bg-transparent border-bottom py-3 px-4"
                      >
                        <h5 class="font-size-16 mb-0">
                          Detail Pesanan
                        </h5>
                      </div>
                      <div class="table-responsive">
                        <table class="table">
                          <thead>
                            <tr>
                              <th>Produk</th>
                              <th>Pcs</th>
                              <th class="text-right">Harga</th>
                            </tr>
                          </thead>
                          <tbody>
                            <template v-for="item in filteredDataFinal">
                              <template
                                v-for="color in item.cloth_colors"
                                v-if="totalQtyIndex(color.id) > 0"
                              >
                                <tr>
                                  <td>
                                    <h6 class="text-truncate font-size-15">
                                      <p class="text-dark m-0">
                                        {{
                                          item.cloth_category
                                            ? item.cloth_category.name
                                            : "-"
                                        }}
                                      </p>
                                    </h6>
                                  </td>
                                  <td>{{ totalQtyIndex(color.id) }}</td>
                                  <td class="text-right">
                                    {{
                                      formattedPrice(totalPriceIndex(color.id))
                                    }}
                                  </td>
                                </tr>
                              </template>
                            </template>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="table-responsive">
                      <table class="table mb-0">
                        <thead>
                          <template v-if="filteredData.length > 0 && !checkParams">
                            <tr>
                              <th class="vertical-align-middle">
                                Diskon (Rp.)
                              </th>
                              <td>
                                <div
                                  class="d-flex justify-content-end align-items-center"
                                >
                                  <div>
                                    <input
                                      type="number"
                                      class="form-control"
                                      v-model="setDataOrder.discount"
                                      id="diskon"
                                    />
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th class="vertical-align-middle">
                                Metode Pembayaran (Rp.)
                              </th>
                              <td>
                                <select
                                  class="form-select"
                                  v-model="selectedPayment"
                                >
                                  <option
                                    v-for="(pay, index) in methodPayments"
                                    :key="index"
                                    :selected="index === 0"
                                    :value="pay.value"
                                    >{{ pay.text }}</option
                                  >
                                </select>
                                <div
                                  class="mt-3"
                                  v-if="
                                    selectedPayment !== 'cash' &&
                                      selectedPayment !== 'Pilih Pembayaran'
                                  "
                                >
                                  <div
                                    class="d-flex justify-content-start align-items-center"
                                  >
                                    <div class="col-md-11">
                                      <input
                                        type="number"
                                        class="form-control"
                                        placeholder="Masukkan Pembayaran"
                                        v-model="
                                          setDataOrder.amount_down_payment
                                        "
                                        id="method_payment"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </template>
                          <tr class="bg-light">
                            <th>Total Pcs</th>
                            <td class="text-end d-flex justify-content-between">
                              <span>: </span>
                              <span class="fw-bold">{{ total_qty }}</span>
                            </td>
                          </tr>
                          <tr class="bg-light">
                            <th>Sub Total Semua Produk</th>
                            <td class="text-end d-flex justify-content-between">
                              <span>: </span>
                              <span class="fw-bold">{{
                                formattedPrice(total_price)
                              }}</span>
                            </td>
                          </tr>
                          <tr class="bg-light" v-if="setDataOrder.discount > 0">
                            <th>Diskon</th>
                            <td class="text-end d-flex justify-content-between">
                              <span>: </span>
                              <span class="fw-bold">{{
                                formattedPrice(setDataOrder.discount)
                              }}</span>
                            </td>
                          </tr>
                          <tr
                            class="bg-light"
                            v-if="setDataOrder.amount_down_payment > 0"
                          >
                            <th>DP</th>
                            <td class="text-end d-flex justify-content-between">
                              <span>: </span>
                              <span class="fw-bold">{{
                                formattedPrice(
                                  parseInt(setDataOrder.amount_down_payment)
                                )
                              }}</span>
                            </td>
                          </tr>
                          <tr
                            class="bg-light"
                            v-if="setDataOrder.amount_down_payment > 0"
                          >
                            <th>Sisa Tagihan</th>
                            <td class="text-end d-flex justify-content-between">
                              <span>: </span>
                              <span class="fw-bold">{{
                                formattedPrice(total_price_dp)
                              }}</span>
                            </td>
                          </tr>
                          <tr class="bg-light">
                            <th>Total Pembayaran</th>
                            <td class="text-end d-flex justify-content-between">
                              <span>: </span>
                              <span class="fw-bold">{{
                                formattedPrice(total_price_final)
                              }}</span>
                            </td>
                          </tr>
                        </thead>
                      </table>
                    </div>
                    <div class="mt-4">
                      <b-button
                        variant="success"
                        class="btn-block w-100 fw-bold fs-5"
                        @click="onSubmit"
                        :disabled="total_qty > 0 ? false : true"
                        >
                          {{ !checkParams ? 'Buat Pesanan' : 'Tambah Pesanan' }}
                        </b-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-card-body>
      <b-card-body v-else>
        <h5 class="text-bold mb-3">
          <skeleton width="30%" height="35px" borderRadius="7px" />
        </h5>
        <b-row>
          <b-col class="mt-3" v-for="row in rowCount" :key="row">
            <skeleton width="50%" height="35px" borderRadius="7px" />
          </b-col>
        </b-row>
        <div class="mt-4 d-flex justify-content-end align-items-center">
          <skeleton width="10%" height="35px" borderRadius="7px" />
        </div>
        <b-row class="mt-4">
          <b-col>
            <div class="row">
              <div class="col-xl-8 h-custom">
                <skeleton width="95%" height="100%" borderRadius="7px" />
              </div>
              <div class="col-xl-4 h-custom">
                <skeleton width="95%" height="80%" borderRadius="7px" />
                <div class="mt-3 h-custom">
                  <skeleton width="95%" height="20%" borderRadius="7px" />
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <!-- modal cloth -->
    <b-modal
      ref="tambah-item-modal"
      size="xl"
      hide-footer
      centered
      title="List Produk"
    >
      <div class="container-fluid" v-if="!isLoadingModal">
        <!-- <div class="row justify-content-center">
          <div
            class="col-sm-4 col-md-3 col-lg-2 col-custom my-2 d-flex"
            v-for="(cloth, indexCloth) in setDataClothDetail"
            :key="indexCloth"
          >
            <div
              class="card card-custom ratio ratio-1x1 animate-card text-white w-100"
              @click="addFormDataSize(cloth.id)"
            >
              <template v-for="color in cloth.cloth_colors">
                <template v-for="size in color.cloth_sizes">
                  <span
                    class="badge bg-info badge-custom"
                    v-if="size.qty > 0 && total_qty > 0"
                    >{{ totalQtyIndex(color.id) }}</span
                  >
                </template>
              </template>
              <img
                :src="
                  cloth.cloth_colors[0].image !== null
                    ? `${apiUrl.baseUrl}/storage/${cloth.cloth_colors[0].image}`
                    : imgDummy
                "
                class="card-img h-100"
                style="object-fit: cover;"
                alt="..."
              />
              <div
                class="card-img-overlay animate-card-img-overlay d-flex flex-column justify-content-start card-body-custom"
              >
                <div class="bottom-left fw-bold card-title-custom d-none">
                  <p>
                    {{ cloth.cloth_category ? cloth.cloth_category.name : "-" }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <b-row class="justify-content-center">
          <b-col
            sm="6"
            lg="3"
            xl="2"
            class="my-2"
            v-for="(cloth, indexCloth) in setDataClothDetail"
            :key="indexCloth"
          >
            <div
              class="card card-custom h-100 card-zoom border-bottom border-bottom-custom border-primary"
              @click="addFormDataSize(cloth.id)"
            >
              <template v-for="color in cloth.cloth_colors">
                <template v-for="size in color.cloth_sizes">
                  <span
                    class="badge bg-info badge-custom"
                    v-if="size.qty > 0 && total_qty > 0"
                    >{{ totalQtyClothIndex(cloth.id) }}</span
                  >
                </template>
              </template>
              <img
                class="img-card-custom rounded"
                :src="cloth.photo ? cloth.photo : imgDummy"
                alt=""
                style="object-fit: cover;"
              />
              <div class="card-body card-body-custom">
                <h6 class="card-title">
                  {{ cloth.cloth_category ? cloth.cloth_category.name : "-" }}
                </h6>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <div v-else>
        <b-row>
          <b-col class="mt-3" v-for="row in rowCountModal" :key="row">
            <skeleton width="100%" height="150px" borderRadius="7px" />
          </b-col>
        </b-row>
      </div>
    </b-modal>

    <!-- modal cloth detail -->
    <b-modal
      ref="tambah-item-modal-detail"
      size="lg"
      hide-footer
      centered
      title="Kuantitas Produk"
      class="pl-0"
    >
      <div class="container-fluid" v-if="!isLoadingModalDetail">
        <template v-if="filteredData.length > 0">
          <multiselect
            v-model="selectedColor"
            :options="setDataClothColor.cloth_colors"
            :searchable="true"
            track-by="id"
            placeholder="Select a color"
          >
            <template slot="option" slot-scope="{ option }">
              {{ option.color !== null ? option.color.name : "-" }}
            </template>
            <template slot="singleLabel" slot-scope="{ option }">
              {{ option.color !== null ? option.color.name : "-" }}
            </template>
          </multiselect>
          <div class="table-responsive">
            <table class="table mt-3">
              <thead class="head-custom fw-bold">
                <tr>
                  <td class="text-end">Harga / Pcs</td>
                  <td class="text-center">Ukuran</td>
                  <td class="text-ceter">Stok</td>
                  <td class="text-center">Kuantitas</td>
                  <td class="text-end">Total Harga</td>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="select in selectedColor.cloth_sizes"
                  :key="select.id"
                >
                  <td class="text-end">
                    {{ formattedPrice(select.price.price) }}
                  </td>
                  <td class="text-center">{{ select.size.name }}</td>
                  <td class="text-center">{{ select.stock - select.qty }}</td>
                  <td class="text-center">
                    <div
                      class="d-flex align-items-center justify-content-center"
                    >
                      <b-button
                        variant="primary"
                        class="rounded-start rounded-0"
                        @click="select.qty--"
                        >-</b-button
                      >
                      <input
                        type="number"
                        class="form-control w-50 rounded-0"
                        style="height: 2.45rem;"
                        v-model="select.qty"
                        :max="select.stock"
                        :min="select.min_stock"
                        :id="select.id + 'form-qty'"
                        :disabled="select.stock > 0 ? false : true"
                      />
                      <b-button
                        variant="primary"
                        class="rounded-end rounded-0"
                        @click="select.qty++"
                        >+</b-button
                      >
                    </div>
                  </td>
                  <td class="text-end">
                    {{ formattedPrice(select.price.price * select.qty) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
      </div>
      <div v-else>
        <skeleton width="100%" height="50px" borderRadius="7px" />
        <b-row class="mt-3">
          <b-col class="mt-3" v-for="row in rowCountModalDetail" :key="row">
            <skeleton width="100%" height="120px" borderRadius="7px" />
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </Layout>
</template>
<style>
.vertical-align-middle {
  vertical-align: middle;
}
.fs-custom {
  font-size: 8.5rem;
}
.img-card-custom {
  margin: 10px;
  height: 50%;
}
.badge-custom {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  margin: 5px;
  top: 0px;
  right: 10px !important;
  font-size: 12px;
  padding: 6px;
  width: 20px;
  height: 20px;
  z-index: 1;
}

.h-custom {
  height: 15rem;
}

.table-bordered {
  border: 1px solid #d4d4d4 !important;
}
.head-custom {
  background-color: var(--bs-table-striped-bg);
}

.animate-card-img-overlay {
  transition: 0.4s;
}

.animate-card-img-overlay:hover {
  background-color: #0000006c;
  overflow: auto;
  cursor: pointer;
}

.animate-card:hover .card-title-custom {
  display: block !important;
  bottom: 0;
}

.card-title-custom {
  position: absolute;
  display: none !important;
}

@media (max-width: 575px) {
  .bg-dark {
    background-color: transparent !important;
    color: #495057 !important;
  }
  .card-custom {
    flex-direction: row;
    height: 6rem;
    margin-bottom: 0;
  }

  .badge-custom {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0px !important;
    right: 200px !important;
    font-size: 12px;
    padding: 6px;
    width: 20px;
    height: 20px;
    z-index: 1;
  }

  .animate-card-img-overlay:hover {
    background-color: #00000000 !important;
    overflow: auto;
    cursor: pointer;
  }

  .card-custom img {
    /* margin: 0; */
    width: 6rem;
    /* height: auto !important; */
    margin-right: 1rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .bottom-left {
    position: unset;
    margin: 0;
  }

  .bottom-left > p {
    margin-bottom: 0;
  }
  .card-body-custom {
    align-items: center !important;
    flex-direction: unset !important;
    padding-left: 7.5rem;
  }
}
@media (min-width: 576px) and (max-width: 660px) {
  .col-custom {
    flex: 0 0 40%;
    max-width: 100%;
  }
}
@media (min-width: 992px) and (max-width: 1100px) {
  .col-custom {
    flex: 0 0 30%;
    max-width: 100%;
  }
}
@media (min-width: 1101px) and (max-width: 1400px) {
  .col-custom {
    flex: 0 0 25%;
    max-width: 100%;
  }
}
</style>
